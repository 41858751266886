import React from "react"; 

const Header = () => { 
return ( 
	<div class="header">
         <h1>BaseStationFive</h1>
        <img src="https://basestationfive.files.wordpress.com/2017/06/cropped-footprint-moon.jpg" alt="BaseStationFive" width="100%"/>
    </div>
); 
}; 
export default Header; 
