import React from "react"; 

const Footer = () => { 
return ( 
	<div class="footer">
		BaseStationFive
	</div>
); 
}; 
export default Footer; 
