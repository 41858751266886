import React from "react";

const About = () => {
	return (
		<div class="pages"> 
			<h1>
				About BaseStationFive
			</h1>
            <p>
                This is my technology sandbox. Visit my Blog on WordPress or follow mw on GitHub.
            </p>
		</div>

       
	);
};

export default About;
