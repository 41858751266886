import React from 'react';

const Blogs = () => {
	return (
        <div> 
		    <h1>You can write your blogs!</h1>
        </div>
	);
};

export default Blogs;
