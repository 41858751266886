import React from 'react';

const Home = () => {
	return (
		<div class="pages">
			<h1>Welcome to BaseStationFive</h1>
			<p>
				One small step for man....
			</p>
		</div>
	);
};

export default Home;

